$('.tabbed-content').tabs();

$('*[data-search-filter]').each(function(){

    var input = this;
    var els = $('*[data-search-filtered]');

    $(input).keyup(function(){

        switch($(this).val().trim()){

            case '':
                $(els).css({display: 'block'});
                break;

            default:
                $(els).each(function(){

                    var name = $(this).data('search-filtered').toLowerCase();
                    var query = $(input).val().trim().toLowerCase();
                    var display = (name.indexOf(query) != -1) ? 'block' : 'none';
                    $(this).css({display: display});
                });
                break;
        }
    });
});

$('*[data-filter]').each(function(){

    var dropdown = this;
    var filter_key = $(this).data('filter');
    var data_key = 'data-filters-' + filter_key;
    var els = $('*[' + data_key + ']');

    function filter(value){

        $(els).each(function(){

            var filter_search = $(this).data('filter-search');
            var filters = $(this).data('filters');
            if (typeof filter_search == 'undefined'){ filter_search = {}; }
            if (typeof filters == 'undefined'){ filters = {}; }

            switch(value){

                case '':
                    delete filter_search[filter_key];
                    delete filters[filter_key];
                    break;

                default:
                    filter_search[filter_key] = 1;
                    var fs = $(this).attr(data_key).split(',');
                    if ($.inArray(value, fs) == -1){

                        delete filters[filter_key];

                    } else{

                        filters[filter_key] = 1;
                    }
                    break;
            }

            $(this).data('filters', filters);
            $(this).data('filter-search', filter_search);
            var display = (Object.keys(filter_search).length > 0 && Object.keys(filters).length < Object.keys(filter_search).length) ? 'none' : 'block';
            $(this).css({display: display});
        });
    }

    $(this).change(function(){ filter($(dropdown).val()); });
    filter($(dropdown).val());
});