$('textarea').autoGrow();

$('.dropdown--links').change(function(){

    document.location = $(this).val()
});

$('form.ajax').submit(function(e){

    e.preventDefault();
    var form = this;
    $.ajax({
        type: $(form).attr('method'),
        url: $(form).attr('action'),
        data: $(form).serialize()        
    });
});

$('form.ajax .no-ajax').click(function(e){

    e.preventDefault();
    var form = $(this).closest('form')[0];
    var input = $('<input type="hidden" name="' + $(this).attr('name') + '" value="' + $(this).val() + '">').appendTo(form);
    form.submit();
});

$(document.body).on('click', '.js-modal .button', function(e){

    e.preventDefault();
    $('.js-modal').remove();
});

$('select[data-multiple]').each(function(index){

    var parent = $(this).parent();
    var label = $(this).data('multiple');
    var no_label = $(this).data('none');
    var no_label_container = false;
    var name = $(this).attr('name');
    var values = $(this).val();
    var advanced = !! $(this).data('multiple-advanced');

    var h3 = $('<h3 />').html(label).insertAfter(parent);
    var list = $('<ul class="multiple-dropdown-list" />').insertAfter(h3);

    $(list).on('click', 'a.remove', function(e){

        e.preventDefault();
        e.stopPropagation();

        if ( !! $(this).parent().data('related-checkbox')){

            $($(this).parent().data('related-checkbox')).prop('checked', false);
        }

        $(this).parent().remove();
        set_no_label();
    });

    if (advanced == true){

        var input_id = 'mta' + index;
        var container = $('<div class="js-multiple-advanced" />').insertBefore(parent);
        var toggler = $('<input type="checkbox" id="' + input_id + '" class="toggler" />').appendTo(container);
        var container_label = $('<label class="toggle" for="' + input_id + '" />').appendTo(container);
        var form_container = $('<div class="form" />').appendTo(container);
        var search_para = $('<p class="search" />').appendTo(form_container);
        $('<input type="text" class="text-input"><span class="togglers"><a class="select-all" href="#">Select all</a> | <a class="deselect-all" href="#">Deselect all</a></span>').appendTo(search_para);
        var checkbox_list = $('<ul />').appendTo(form_container);

        $(document.body).click(function(){

            $(toggler).prop('checked', false);
        });

        $(container).click(function(e){

            e.stopPropagation();
        });

        $(this).children().each(function(){

            if (this.value == ''){

                $(container_label).html($(this).html());

            } else{

                var li = $('<li><label><input type="checkbox" value="' + $(this).val() + '"> ' + $(this).html() + '</label></li>').appendTo(checkbox_list);
                if ($.inArray($(this).val(), values) != -1){

                    $(li).find('input').trigger('click');
                    var li2 = $('<li data-value="' + $(this).val() + '"><input type="hidden" name="' + name + '" value="' + $(this).val() + '">' + $(this).html() + ' <a class="remove" href="#"><i class="fa fa-close"></i></a></li>').appendTo(list);
                    $(li2).data('related-checkbox', $(li).find('input'));
                    $(li).find('input').data('li', li2);
                }
            }
        });

        $(checkbox_list).find('input').click(function(){

            switch(true){

                case ($(this).prop('checked') == true):
                    var li = $(list).find('li[data-value="' + $(this).val() + '"]');
                    if (li.length == 0){

                        li = $('<li data-value="' + $(this).val() + '"><input type="hidden" name="' + name + '" value="' + $(this).val() + '">' + $(this).parent().text() + ' <a class="remove" href="#"><i class="fa fa-close"></i> Delete</a></li>').appendTo(list);
                        $(this).data('li', li);
                        $(li).data('related-checkbox', this);
                    }
                    break;

                case ($(this).prop('checked') == false):
                    if ( !! $(this).data('li')){
                        $(this).data('li').remove();
                    }
                    break;
            }

            set_no_label();
        });

        $(search_para).find('.select-all').click(function(e){

            e.preventDefault();
            $(checkbox_list).find('input').each(function(){

                if ($(this).prop('checked') == false){

                    $(this).trigger('click');
                }
            });
        });

        $(search_para).find('.deselect-all').click(function(e){

            e.preventDefault();
            $(checkbox_list).find('input').each(function(){

                if ($(this).prop('checked') == true){

                    $(this).trigger('click');
                }
            });
        });

        $(search_para).find('input').keyup(function(){

            var keywords = this.value.trim().toLowerCase();
            switch(keywords){

                case '':
                    $(checkbox_list).children().css({display: 'block'});
                    break;

                default:
                    $(checkbox_list).children().each(function(){

                        var val = $(this).text().trim().toLowerCase();
                        var display = (val.indexOf(keywords) != -1) ? 'block' : 'none';
                        $(this).css({display: display});
                    });
                    break;
            }
        });

    } else{

        $(values).each(function(){

            $('<li data-value="' + this + '"><input type="hidden" name="' + name + '" value="' + this + '">' + this + ' <a class="remove" href="#"><i class="fa fa-close"></i></a></li>').appendTo(list);
        });

        var dropdown = $('<select class="dropdown" />').html($(this).html()).appendTo(parent);
        $(dropdown).children().removeAttr('selected');

        $(dropdown).change(function(){

            if ($(dropdown).val() != ''){

                var found = false;
                $(list).children().each(function(){

                    if ($(this).data('value') == $(dropdown).val()){

                        found = true;
                    }
                });
                if (found == false){

                    $('<li data-value="' + $(dropdown).val() + '"><input type="hidden" name="' + name + '" value="' + $(dropdown).val() + '">' + $(dropdown).val() + ' <a class="remove" href="#"><i class="fa fa-close"></i> Delete</a></li>').appendTo(list);
                }

                $(dropdown).val('');
            }

            set_no_label();
        });
    }

    $(this).remove();
    set_no_label();

    function set_no_label(){

        switch(true){

            case ( !! no_label && $(list).children().length == 0):
                no_label_container = $('<p>' + no_label + '</p>').insertAfter(h3);
                break;

            case ( !! no_label && $(list).children().length > 0):
                $(no_label_container).remove();
                break;
        }
    }
});

$('.dropdown').each(function(){

    var select = this;
    var children = $(select).children();
    $(select).wrap('<span />');
    var custom_select = $('<div class="custom-select" />').insertAfter(select);

    if ($(select).hasClass('dropdown--small') == true){

        $(custom_select).addClass('custom-select--small');
    }

    if ($(select).attr('id')){

        $(custom_select).attr('id', $(select).attr('id'));
    }

    if ($(select).attr('style')){

        $(custom_select).attr('style', $(select).attr('style'));
    }

    $(select).css({display: 'none'});

    var label = $('<div class="custom-select__label" />').appendTo(custom_select);
    var list = $('<ul class="custom-select__options" />').appendTo(custom_select);
    var width = 0;

    $(children).each(function(){

        var value = $(this).val();
        var lab = $(this).html();
        var option = $('<li class="custom-select__option" data-value="' + value + '" />').appendTo(list);
        var link = $('<a href="#">' + lab + '</a>').appendTo(option);
        $(link).click(function(e){

            e.preventDefault();
            $(select).val(value);
            $(select).trigger('change');
        });
    });

    $(label).html($(children[$(select).prop('selectedIndex')]).html());

    $(select).change(function(){

        $(label).html($(children[$(select).prop('selectedIndex')]).html());
        $(custom_select).removeClass('custom-select--open');
    });

    $(document.body).click(function(){

        $(custom_select).removeClass('custom-select--open');
    });

    $(custom_select).click(function(e){

        e.stopPropagation();
    });

    $(label).click(function(){

        $(custom_select).toggleClass('custom-select--open');
    });
});

$('*[data-toggle-display]').each(function(){

    var el = this;
    var parts = $(el).data('toggle-display').split(':');
    var selector = parts[0];
    var values = parts[1].split(',');
    var dropdown = $(selector);
    var visibility, position;

    $(dropdown).change(function(){

        visibility = ($.inArray($(dropdown).val(), values) == -1) ? 'hidden' : 'visible';
        position = ($.inArray($(dropdown).val(), values) == -1) ? 'absolute' : 'relative';

        $(el).parent().css({
            visibility: visibility,
            position: position
        });
    });

    setTimeout(function(){
        visibility = ($.inArray($(dropdown).val(), values) == -1) ? 'hidden' : 'visible';
        position = ($.inArray($(dropdown).val(), values) == -1) ? 'absolute' : 'relative';

        $(el).parent().css({
            visibility: visibility,
            position: position
        });
    }, 1);
});

$('.js-password').each(function(){

    var input = this,
        clone = $(this).clone();

    $(input).wrap('<span class="js-password-wrapper" />');
    var wrapper = $(input).parent();

    $(clone).attr('type', 'text');
    $(clone).removeClass('js-password');
    $(clone).addClass('plaintext');
    $(clone).removeAttr('name');
    $(clone).removeAttr('id');
    $(clone).insertAfter(input);

    $(input).keyup(function(){

        $(clone).val($(input).val());
    });

    $(clone).keyup(function(){

        $(input).val($(clone).val());
    });

    var toggler = $('<span class="toggler" />').appendTo(wrapper);
    $(toggler).click(function(){

        $(wrapper).toggleClass('display');
    });
});

$('input[data-enable]').each(function(){

    var attr = $(this).data('enable').split(':');
    var id = attr[0],
        val = attr[1];
    var input = this,
        dropdown = $('#' + id);

    $(dropdown).change(check_dropdown);
    check_dropdown();

    function check_dropdown(){

        switch($(dropdown).val()){

            case val:
                $(input).removeAttr('disabled');
                break;

            default:
                $(input)
                    .val('')
                    .attr('disabled', 'disabled');
                break;
        }
    }
});