$('.date-input .text-input').datepicker({
    dateFormat: 'dd/mm/yy',
    dayNamesMin: ['S','M','T','W','T','F','S'],
    onSelect: function(d){

        if ($(this).data('from-filter') || $(this).data('to-filter')){

            var filter_key;
            var input = this;

            switch(true){

                case ( !! $(this).data('from-filter')):
                    filter_key = 'from-date';
                    break;

                case ( !! $(this).data('to-filter')):
                    filter_key = 'to-date';
                    break;
            }

            $('*[data-filter-date]').each(function(){

                var filter_search = $(this).data('filter-search');
                var filters = $(this).data('filters');
                if (typeof filter_search == 'undefined'){ filter_search = {}; }
                if (typeof filters == 'undefined'){ filters = {}; }

                switch(d){

                    case '':
                        delete filter_search[filter_key];
                        delete filters[filter_key];
                        break;

                    default:
                        var d1 = d.split('/');
                        d1 = d1[1] + '/' + d1[0] + '/' + d1[2] + ' 00:00:00';
                        var date1 = new Date($(this).data('filter-date') + ' 00:00:00').getTime();
                        var date2 = new Date(d1).getTime();
                        switch(true){

                            case ( !! $(input).data('from-filter') && date1 >= date2):
                                filter_search[filter_key] = 1;
                                filters[filter_key] = 1;
                                break;

                            case ( !! $(input).data('from-filter') && date1 < date2):
                                filter_search[filter_key] = 1;
                                delete filters[filter_key];
                                break;

                            case ( !! $(input).data('to-filter') && date1 <= date2):
                                filter_search[filter_key] = 1;
                                filters[filter_key] = 1;
                                break;

                            case ( !! $(input).data('to-filter') && date1 > date2):
                                filter_search[filter_key] = 1;
                                delete filters[filter_key];
                                break;
                        }
                        break;
                }

                $(this).data('filters', filters);
                $(this).data('filter-search', filter_search);
                var display = (Object.keys(filter_search).length > 0 && Object.keys(filters).length < Object.keys(filter_search).length) ? 'none' : 'block';
                $(this).css({display: display});
            });
        }
    }
});