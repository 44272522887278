$('form[data-autosave]').each(function(){

    var form = this;
    var interval;
    var state = $('<div class="form-autosave-state">Your form is autosaving. Please do not close this window <i class="fa fa-refresh fa-spin"></i></div>').appendTo($('.main'));

    $(form).find('input, textarea').keyup(function(){

        clearTimeout(interval);
        interval = setTimeout(autosave, 300);
    });

    $(form).find('input[type=checkbox], input[type=radio]').click(autosave);

    $(form).find('select').change(autosave);

    function autosave(){

        $(state).addClass('show');

        $.ajax({
            type: $(form).attr('method'),
            url: $(form).attr('action'),
            data: $(form).serialize() + '&ajax=1',
            success: function(response){

                $(state).removeClass('show');
                $('*[data-id]').val(response);
            }
        });
    }
});