(function(){

    var inputs = $('.time-input input');
    var active_input = '';

    if (inputs.length > 0){

        var wrapper = $('<div class="time-picker" />').appendTo(document.body);
        var val_wrapper = $('<div class="value" />').appendTo(wrapper);
        var time_val = $('<div class="time-val" />').appendTo(val_wrapper);
        var time_val_span = $('<div class="val" />').appendTo(time_val);
        var time_val_am = $('<div class="time-val-am">AM</div>').appendTo(time_val);
        var time_val_pm = $('<div class="time-val-pm">PM</div>').appendTo(time_val);
        var time_picker = $('<div class="clock" />').appendTo(wrapper);
        var line = $('<div class="line" />').appendTo(time_picker);
        var am_link = $('<a class="am-btn" href="#">AM</a>').appendTo(wrapper);
        var pm_link = $('<a class="pm-btn" href="#">PM</a>').appendTo(wrapper);
        var time;
        var am_pm;

        for (var i = 1; i <= 12; i++){

            var num = $('<a href="#" class="num num-' + i + '"><span>' + i + '</span></a>').appendTo(time_picker);
            $(num).click(function(e){

                e.preventDefault();
                var hour = $(this).text();
                time = hour + ':00';
                $(time_val_span).html(time);
                $(active_input).val(time + am_pm);
                $(line)
                    .removeAttr('class')
                    .addClass('line')
                    .addClass('line--show')
                    .addClass('hour-' + hour);
                $(time_picker).find('.num').removeClass('num--active');
                $(time_picker).find('.num-' + hour).addClass('num--active');
            });
        }

        $(document.body).click(function(){

            $(wrapper).removeClass('time-picker--show');
        });

        $(inputs).click(function(e){

            e.stopPropagation();
        });

        $(am_link).click(function(e){

            e.preventDefault();
            $(time_val_am).addClass('active');
            $(time_val_pm).removeClass('active');
            $(am_link).addClass('active');
            $(pm_link).removeClass('active');
            am_pm = 'AM';
            $(active_input).val(time + am_pm);
        });

        $(pm_link).click(function(e){

            e.preventDefault();
            $(time_val_am).removeClass('active');
            $(time_val_pm).addClass('active');
            $(am_link).removeClass('active');
            $(pm_link).addClass('active');
            am_pm = 'PM';
            $(active_input).val(time + am_pm);
        });

        $(inputs).focus(function(){

            active_input = this;
            $(wrapper).addClass('time-picker--show');
            var pos = $(this).offset();
            var width = $(this).outerWidth();
            var height = $(this).outerHeight();
            var window_width = $(window).width();
            var right = window_width - (pos.left + width);
            var top = pos.top + height;
            $(wrapper).css({
                right: right + 'px',
                top: top + 'px'
            });

            $(wrapper).find('.num--active').removeClass('num--active');

            if ($(this).val() != ''){

                var hour = $(this).val().split(':')[0];
                time = $(this).val().replace(/^(.*?)[a-z]{2}$/i, '$1');
                am_pm = $(this).val().replace(/^.*?([a-z]{2})$/i, '$1').toUpperCase();

                $(line)
                    .removeAttr('class')
                    .addClass('line')
                    .addClass('line--show')
                    .addClass('hour-' + hour);

                $(wrapper).find('.num-' + hour).addClass('num--active');

            } else{

                time = '0:00';
                am_pm = '';

                $(line)
                    .removeAttr('class')
                    .addClass('line');
            }

            $(time_val_span).html(time);
            if (am_pm == 'AM'){

                $(time_val_am).addClass('active');
                $(time_val_pm).removeClass('active');
                $(am_link).addClass('active');
                $(pm_link).removeClass('active');

            } else if (am_pm == 'PM'){

                $(time_val_am).removeClass('active');
                $(time_val_pm).addClass('active');
                $(am_link).removeClass('active');
                $(pm_link).addClass('active');

            } else{

                $(time_val_am).removeClass('active');
                $(time_val_pm).removeClass('active');
                $(am_link).removeClass('active');
                $(pm_link).removeClass('active');
            }
        });

        $(wrapper).click(function(e){

            e.stopPropagation();
        });
    }

})();